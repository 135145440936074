import React from "react"
import Speaker from "./speaker"
import { navigate } from "gatsby"

function printBSTTime(start_time, end_time) {
  start_time = start_time.split(":").map(Number);
  end_time = end_time.split(":").map(Number);

  start_time[0] += 5;
  end_time[0] += 5;

  if (start_time[0] >= 24) {
    start_time[0] -= 24;
  }
  if (end_time[0] >= 24) {
    end_time[0] -= 24;
  }

  start_time = start_time.map((val) => (val < 10 ? "0" + val : val));
  end_time = end_time.map((val) => (val < 10 ? "0" + val : val));

  return `${start_time.join(":")} - ${end_time.join(":")} BST`;
}

const Event = props => {
  const showBtn = props.showBtn

  return (
    <div
      className={
        "mb-10 pb-20 pr-20 pl-25 pt-20 font-secondary-regular text-size-medium agenda-wrapper " +
        (props.value.is_live
          ? "is_live background-color-primary-1 color-monochrome-1"
          : "") +
        (props.value.is_past ? "is_past" : "")
      }
    >
      <div className="agendaHeader">
        <div className="d-sm-flex sm-between">
          <div className="presentation-wrapper">
            {props.value.presentation_title !== "" ? (
              <div className="presentationTitle font-secondary-bold mb-5">
                {props.value.presentation_title}
              </div>
            ) : (
              ""
            )}
            <div className="d-sm-flex presentation-time" style={{gap:"5px"}}>
              {props.value.start_time &&
              props.value.end_time &&
              props.value.timezone ? (
                <>
                  <div className="presentationTitle font-secondary-bold mb-15 mb-xs-5">
                    {/* {props.value.start_time} - {props.value.end_time}{" "}
                  {props.value.timezone} */}
                    {props.value.start_time !== "00:00"
                      ? props.value.start_time
                      : ""}{" "}
                    {props.value.start_time !== "00:00" &&
                    props.value.end_time !== "00:00"
                      ? "-"
                      : ""}{" "}
                    {props.value.end_time !== "00:00"
                      ? props.value.end_time
                      : ""}{" "}
                    {props.value.start_time !== "00:00" ||
                    props.value.end_time !== "00:00"
                      ? props.value.timezone
                      : ""}
                  {" "}|{" "}
                    {props.value.timezone === "EST" ? (
                      <>
                        {printBSTTime(
                          props.value.start_time,
                          props.value.end_time
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              {!!props.value.is_live && (
                <div className="ml-xs-0 ml-20 mb-15 mb-xs-10 color-primary-2 font-secondary-bold live-now">
                  Live now
                </div>
              )}
            </div>
            <div className="agendaBody">
              {props.value.speakers.map((value, index) => {
                return <Speaker key={index} value={value} />
              })}
            </div>
          </div>
          {!!props.value.is_live && showBtn && (
            <div className="agenda-btn mt-xs-30">
              <button
                className="button font-secondary-bold _a_watchnow color-primary-1"
                onClick={() => navigate("/live")}
              >
                Watch now
              </button>
            </div>
          )}
          {!!props.value.is_past && showBtn && (
            <div className="agenda-btn mt-xs-30">
              <button
                className="button button--secondary font-secondary-bold _a_watchondemand"
                onClick={() =>
                  navigate("/resources", {
                    state: {
                      activeTab: props.tabIndex,
                      selectedPresentation: props.value.presentation_id,
                    },
                  })
                }
              >
                Watch on demand
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Event
