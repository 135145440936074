import React, { useState, useEffect } from "react"
import * as moment from "moment"

const Counter = () => {
  const [showClock, setShowClock] = useState(false)
  const [eventStatus, setEventStatus] = useState(false)
  const [isEventActive, setIsEventActive] = useState(false)
  const [startDate, setStartDate] = useState(0)
  const [tickerValue, setTickerValue] = useState({
    days: "0",
    hours: "0",
    minutes: "0",
    seconds: "0",
  })

  const fetchStartDate = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/getEventDetails`
    )
    response = await response.json()
    if (
      response &&
      response.status === 200 &&
      response.result &&
      response.result.event &&
      response.result.event.startDate
    ) {
      let fetchedDate = moment.utc(response.result.event.startDate).valueOf()
      const currentDate = moment.utc().valueOf()
      let endDate = moment.utc(response.result.event.endDate).valueOf()

      if (endDate > currentDate) {
        setIsEventActive(true);
      }
      setStartDate(fetchedDate)
    }
  }

  //This is called on every render of component
  useEffect(() => {
    fetchStartDate()
  }, [])

  //This is called when start date state is updated
  useEffect(() => {
    if (startDate != 0) {
      const ticker = setInterval(() => {
        const currentDate = moment.utc().valueOf()
        const timeToStart = startDate - currentDate

        if (timeToStart < 0) {
          clearInterval(ticker)
          setEventStatus(true)
          setShowClock(true)
          return false
        } else {
          setShowClock(true)
        }

        setTickerValue({
          days: Math.floor(timeToStart / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (timeToStart % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((timeToStart % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((timeToStart % (1000 * 60)) / 1000),
        })
      }, 1000)

      return () => clearInterval(ticker)
    }
  }, [startDate])

  return (
    isEventActive && (<div className="counter background-color-primary-2 d-xs-none d-md-flex color-monochrome-1 xs-middle xs-start pt-40 pr-50 pb-40 pl-40">
      {(!!startDate &&
        showClock) ?
        (!eventStatus ? (
          <>
            <div className={"font-secondary-bold text-size-h3 pr-50 color-primary-1"}>
              Event starts in:
            </div>
            <div className={"counter-value d-xs-flex xs-between color-primary-1"}>
              <div className={"d-xs-flex xs-column xs-middle color-primary-1"}>
                {tickerValue.days}
                <span className={"font-secondary-regular text-size-medium color-primary-1"}>
                  days
                </span>
              </div>
              :
              <div className={"d-xs-flex xs-column xs-middle color-primary-1"}>
                {tickerValue.hours}
                <span className={"font-secondary-regular text-size-medium color-primary-1"}>
                  hours
                </span>
              </div>
              :
              <div className={"d-xs-flex xs-column xs-middle color-primary-1"}>
                {tickerValue.minutes}
                <span className={"font-secondary-regular text-size-medium color-primary-1"}>
                  minutes
                </span>
              </div>
              :
              <div className={"d-xs-flex xs-column xs-middle color-primary-1"}>
                {tickerValue.seconds}
                <span className={"font-secondary-regular text-size-medium color-primary-1"}>
                  seconds
                </span>
              </div>
            </div>
          </>
        ) : (
          <div
            className={
              "font-secondary-bold width-full text-size-h2 text-align-center color-primary-1"
            }
          >
            Event started
          </div>
        )):(
          <div
            className={
              "font-secondary-bold width-full text-size-h2 text-align-center color-primary-1"
            }
          >
            Loading...
          </div>
        
        )}
    </div>
  ));
}

export default Counter
