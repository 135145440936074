/* global _paq */
import React, { useEffect, useState } from 'react';
import '../wdyr';
import { window } from 'browser-monads';
import { navigate, Link } from 'gatsby';
import {
  Container,
  Row,
  Column,
} from 'atomic-core/components/patterns/00-base/grid';
import Heading from 'atomic-core/components/patterns/01-atoms/texts/heading/Heading';
import Text from 'atomic-core/components/patterns/01-atoms/texts/text/Text';
import FormItem from 'atomic-core/components/patterns/02-molecules/forms/FormItem';
import useForm from '../utils/useForm';
import validate from '../utils/validation/loginValidation';
import { isLoggedIn } from '../services/auth';
import { Translations } from '../utils/translations';
import Agenda from '../components/agenda';
import Banner from '../components/shared/hero';
import Counter from '../components/shared/counter';
import AuthErrorMessage from '../components/shared/auth';
import { PASSWORD_AND_USERNAME_DIDNOT_MATCH, USERNAME_DIDNOT_MATCH,REGISTRATION_TYPE_NONE } from '../utils/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import { isMatomoEnabled } from '../data/global';
import { isBoolean } from "../utils/global"
import SEO from '../components/seo';
import {utc} from "moment"

function Login(props) {
  const [agendaData, setagendaData] = useState({
    days: [],
    presentations: [],
    activeTabIndex: 0,
    title: 'Agenda',
  });

  const [ssoError,setSsoError] = useState(null);

  const handleSSOLogin = async (e) => {
    e.preventDefault();
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/getEventLoginDateTime`
    )
    response = await response.json()
    if (
      response &&
      response.status === 200 &&
      response.result &&
      response.result.event &&
      response.result.event.loginDateTime
    ) {
      
      let fetchedDate = utc(response.result.event.loginDateTime);
      const currentDate = utc();

      if (fetchedDate.isAfter(currentDate)){
        setSsoError(Translations.ssoPage.eventNotActive);
      } else{
        setSsoError(null);
        const ssoForm = document.getElementById("sso_form");
        ssoForm.submit();
      } 
    } else {
      setSsoError(Translations.ERROR.SOMETHING_WENT_WRONG);
    }
  }

  const [gReCaptchaResponse, setGReCaptchaResponse] = useState("");
  const [displayCaptcha, setDisplayCaptcha] = useState(false);
  const resetCaptcha = () => {
    window.grecaptcha.reset();
    setGReCaptchaResponse("");
  }
  const renderCaptcha = () => {
    try {
      window.onCaptchaStateChange = () => {
        let captchaResponse = window.grecaptcha.getResponse();
        setGReCaptchaResponse(captchaResponse);
        values.gReCaptcha = captchaResponse;
      };
      window.recaptchaExpired = () => {
        resetCaptcha();
      };
      window.grecaptcha.render("recaptcha-container", {
        sitekey: `${process.env.GOOGLE_RECAPTCHA_SITEKEY}`,
      });
    } catch (e) {
      setError(e.message || Translations.ERROR.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${process.env.API_URL}/${process.env.EVENT_ID}/agenda`,
      );
      const data = await response.json();
      if (data && data.status === 200 && data.result) {
        setagendaData({
          activeTabIndex: data.result.activeTabIndex,
          days: data.result.agenda.days,
          title: data.result.agenda.agenda_title,
          presentations: data.result.presentations,
        });
      }
    }
    fetchData();
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    values.gReCaptcha = gReCaptchaResponse;
  }, [gReCaptchaResponse]);

  const [error, setError] = useState(null);

  const handleLogin = async ({ email, password }) => {
    try {
      if (!(typeof window !== 'undefined')) return false;

      let commonObj = {
        email,
        displayCaptcha,
        gReCaptchaResponse,
        eventId: process.env.EVENT_ID
      }
      if(!isBoolean(process.env.LOGIN_WITHOUT_PASSWORD)){
        commonObj.password = password;
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(commonObj),
      };

      const response = await fetch(`${process.env.API_URL}/loginv2`, requestOptions);
      const contentType = response.headers.get('content-type');
      if (
        response.status !== 200
        && contentType
        && contentType.indexOf('application/json') === -1
      ) {
        return setError(await response.text());
      }
      const data = await response.json();

      if (data && data.status === 200) {
        const userSession = {
          isLoggedIn: true,
        };
        userSession.firstName = data?.result?.firstName || "";
        userSession.lastName = data?.result?.lastName || "";
        userSession.userId = data?.result?.userId || "";
        if(isMatomoEnabled && data?.result?.userId){
          _paq.push(['setUserId', data.result.userId]);
        }
        window.localStorage.veUser = JSON.stringify(userSession);
        navigate('/dashboard');
      } else if (data.error === PASSWORD_AND_USERNAME_DIDNOT_MATCH || data.error === USERNAME_DIDNOT_MATCH) {
        if (data.displayCaptcha === true) {
          setDisplayCaptcha(true);
          resetCaptcha();
        }     
        setError(
            (!isBoolean(process.env.LOGIN_WITHOUT_PASSWORD))
            ?
            Translations.ERROR.THE_DETAILS_YOU_HAVE_ENTERED_NOT_CORRECT
            :
            Translations.ERROR.THE_DETAIL_YOU_HAVE_ENTERED_NOT_CORRECT_FOR_NO_PASSWORD
          );
      } else {
        if (data.displayCaptcha === true) {
          setDisplayCaptcha(true);
          renderCaptcha();
        } 
        setError(data.error);
      }
    } catch (e) {
      setError(e.message || Translations.ERROR.SOMETHING_WENT_WRONG);
    }
  };

  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(
    handleLogin,
    validate,
    setError,
  );

  if (isLoggedIn()) {
    navigate('/dashboard');
    return false;
  }
  return (
    <>
      <SEO title="" />
      <div className="page auth height-full color-monochrome-1">
        <Container fluid globalModifiers="d-xs-flex xs-column height-full">
          <Row globalModifiers="">
            <Banner />
          </Row>
          <Row globalModifiers="content">
            <Column
              sm={12}
              md={6}
              globalModifiers="background-color-primary-1 pt-60 pr-70 pb-60 pl-70"
            >
               <Heading
                headingType="h2"
                globalModifiers="pt-0 mb-0 font-primary-bold text-size-h3 color-secondary-1"
              >
                {!isBoolean(process.env.LOGIN_WITHOUT_PASSWORD) ? Translations.loginPage.title : Translations.loginWithoutPasswordPage.title}
              </Heading>
              {isBoolean(process.env.LOGIN_WITH_SSO) &&  (process.env.REGISTRATION_TYPE === REGISTRATION_TYPE_NONE)&& (
              <div class="text text-size-medium font-secondary-regular mt-10 mb-20 color-secondary-1">{Translations.ssoPage.description}</div>
              )}
              {isBoolean(process.env.LOGIN_WITH_SSO) && (
                <div id="section section-sso-login" className="mt-10 mb-10">
                  <form
                    id="sso_form"
                    method="post"
                    onSubmit={handleSSOLogin}
                    action="/api/ssoAuth"
                  >
                    <input
                      type="hidden"
                      name="eventId"
                      value={process.env.EVENT_ID}
                    />
                    <input
                      type="submit"
                      className="button button--primary _a_login color-primary-1"
                      value={Translations.ssoPage.buttonText}
                    />
                    {ssoError? <AuthErrorMessage text={ssoError} /> : ""}
                  </form>
                </div>
              )}
               {isBoolean(process.env.LOGIN_WITH_SSO) &&  (process.env.REGISTRATION_TYPE !== REGISTRATION_TYPE_NONE)&& (
               <div class="separator horizontal-separator text text-size-medium font-secondary-bold mt-25 color-secondary-1">OR</div>
               )}
              {process.env.REGISTRATION_TYPE !== REGISTRATION_TYPE_NONE && (
              <div id="section section-login">
              <Text
                globalModifiers="text-size-medium font-secondary-regular mt-10 color-secondary-1"
              >
                {!isBoolean(process.env.LOGIN_WITHOUT_PASSWORD) ? Translations.loginPage.description : Translations.loginWithoutPasswordPage.description}
              </Text>
              <Text
                globalModifiers="text-size-medium font-secondary-bold mt-10 color-secondary-1"
              >
              {!isBoolean(process.env.LOGIN_WITHOUT_PASSWORD) ? Translations.loginPage.description2 : Translations.loginWithoutPasswordPage.description2}
              </Text>
              <form onSubmit={handleSubmit} method="post" autoComplete="off">
                <FormItem globalModifiers="mt-20 mb-20">
                  <div className="form-item__control textfield">
                    <input
                      className={`form-control ${errors.email ? 'form-control--error' : ''
                        }`}
                      name="email"
                      type="email"
                      value={values.email || ''}
                      onChange={handleChange}
                      placeholder="Email address"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-item__error-message">
                    {errors.email}
                  </div>
                </FormItem>

                {!isBoolean(process.env.LOGIN_WITHOUT_PASSWORD) && (  <FormItem globalModifiers="mb-40">
                  <div className="form-item__control textfield">
                    <input
                      className={`form-control ${errors.password ? 'form-control--error' : ''
                        }`}
                      name="password"
                      type="password"
                      onChange={handleChange}
                      placeholder="Password"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-item__error-message">
                    {errors.password}
                  </div>
                </FormItem>
                )}
                {displayCaptcha && (
                  <FormItem globalModifiers={"mb-40"}>
                    <div className={"form-item__control textfield"}>
                      <div className={"d-xs-flex"}>
                        <div
                          className="g-recaptcha"
                          id="recaptcha-container"
                          data-sitekey={process.env.GOOGLE_RECAPTCHA_SITEKEY}
                          data-callback="onCaptchaStateChange"
                          data-expired-callback="recaptchaExpired"
                        ></div>
                      </div>
                    </div>
                    {!gReCaptchaResponse && (
                      <div className={"form-item__error-message"}>
                        {errors.gReCaptcha}
                      </div>
                    )}
                  </FormItem>
                )}

                {error ? <AuthErrorMessage text={error} /> : ''}
                <input
                  type="submit"
                  className="button button--primary _a_login color-primary-1"
                  value="Log in"
                />
               {!isBoolean(process.env.LOGIN_WITHOUT_PASSWORD) && (  <div className="mt-20"><Link className="link text-underline color-secondary-1" to="/reset">Forgot your password?</Link></div> )}
              </form>
              </div>
            )} 
              {/* <Text
                globalModifiers="text-size-medium font-secondary-bold mt-40 color-secondary-1"
              >
                If you do not have IDX email but have been invited to register for this event, please click <Link className="link text-underline color-secondary-1" to="/register">here</Link> to go to registration page and register using your personal email address.
              </Text> */}
          
              <Text
                globalModifiers="text-size-medium font-secondary-bold mt-40 color-secondary-1"
              >
                In case of any issues please contact us using the <Link className="link text-underline color-secondary-1" to="/help">Help Page</Link>.
              </Text>

              {process.env.REGISTRATION_TYPE !== REGISTRATION_TYPE_NONE && (
                <div id="section section-register">
                  <hr className="mt-40 mb-40" />

                  <Heading
                    headingType="h2"
                    globalModifiers="mt-0 mb-0 font-primary-bold text-size-h3 color-secondary-1"
                  >
                    Not registered?
                  </Heading>
                  <Text
                globalModifiers="text-size-medium font-secondary-regular mt-35 mb-40 color-secondary-1"
              >
                You must be registered for this event to be able to log in.
                {' '}
                <br />
                If you have not yet registered, please follow the link below.
              </Text>
              <Link to="/register" className="button button--primary color-primary-1">
                Register now
              </Link>
                </div>
              )}
            </Column>
            <Column sm={12} md={6} globalModifiers="pl-0 pr-0 background-color-primary-1">
              <Counter />
              <div className="pt-30 pb-40 pr-40 pl-40 background-color-primary-1">
                <div className="card-title background-color-primary-1 ">
                  <h4 className="mb-0 text-size-h3 font-primary-bold color-secondary-1 mb-30">
                    {agendaData.title}
                  </h4>
                </div>
                <Agenda
                  days={agendaData.days}
                  presentations={agendaData.presentations}
                  startIndex={agendaData.activeTabIndex}
                />
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
